import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import toast from 'react-hot-toast';
import { invalidDateStr } from './staticData';
dayjs.extend(utc);
export const isDateInThePast = (date) => {
    const now = new Date();
    const today = new Date(now.getFullYear(), now.getMonth(), now.getDate());
    const givenDate = new Date(date);
    const checkDate = new Date(givenDate.getFullYear(), givenDate.getMonth(), givenDate.getDate());
    return checkDate < today;
}
export const today = new Date();
export const mdyFormat = (date) => {
    return date?.replace(/^(\d{4})-(\d{2})-(\d{2})$/, (_, year, day, month) => `${year}-${month}-${day}`)
}
export const ymdDateFormat=(date) =>{
    return dayjs(date).format('YYYY-MM-DD').toString()
}
export const formatDate = (date, formatString = 'YYYY-MM-DD') => {
    return dayjs(date).format(formatString)
};

export const formatDateMDY = (date) => {
    return `${date?.getDate()}-${date?.getMonth() + 1}-${date?.getFullYear()}`;
  };
  
export const formatDateYMD = (date) => {
    return `${date?.getFullYear()}-${date?.getMonth() + 1}-${date?.getDate()}`;
  };
  
export const getDateRange = (timeFrame) => {
      const today = new Date();
      let startDate, endDate;
    
      switch (timeFrame) {
        case 'today':
          startDate = new Date(today);
          endDate = new Date(today);
          break;
    
        case '1 week':
          startDate = new Date(today);
          startDate.setDate(today.getDate() - 7);
          endDate = today;
          break;
    
        case '1 month':
          startDate = new Date(today);
          startDate.setMonth(today.getMonth() - 1);
          endDate = today;
          break;
    
        case '6 months':
          startDate = new Date(today);
          startDate.setMonth(today.getMonth() - 6);
          endDate = today;
          break;
    
        case '1 year':
          startDate = new Date(today);
          startDate.setFullYear(today.getFullYear() - 1);
          
          // Adjusting for leap year
          if (today.getDate() === 29 && today.getMonth() === 1) {
            startDate.setDate(1);
          }
    
          endDate = today;
          break;
    
        default:
          console.log('invalid date format')
      }
    
      return {
        from: formatDateMDY(startDate),
        to: formatDateMDY(endDate),
      };
    };
  
export const dateWithSlashes = (dates) => {
  return  `${dates?.from}/${dates?.to}`
}


export const utcFormat = (date) => {
  return dayjs(date).utc().format('YYYY-MM-DDTHH:mm:ss.SSS[Z]')   
};
export const getCurrMonth = () => {
  return (new Date()?.getMonth())
}

export const getCurrentMonthStr = (num) => {
return months[num]
}

export const months = [
  'January', 'February', 'March', 'April', 'May', 'June',
  'July', 'August', 'September', 'October', 'November', 'December'
];

export const  getPastDate = (interval) => {

  const date = dayjs(new Date());

  let pastDate;
  
  switch (interval) {
    case 'week':
      pastDate = date.subtract(1, 'week');
      break;
    case 'month':
      pastDate = date.subtract(1, 'month');
      break;
    case '6months':
      pastDate = date.subtract(6, 'month');
      break;
    case 'year':
      pastDate = date.subtract(1, 'year');
      break;
    case 'today':
      pastDate = date.startOf('day');
      break;
    default:
      toast.error(invalidDateStr)
  }

  return pastDate.toISOString();
}


export const extractDateFromDayjs = (val) => {
  const extractedDate = val ? val?.toString()?.split(',')[1]?.split(' ')?.reduce((prev, curr, index) => {
    return index < 4 ? prev + curr : prev + ''
  }) : ''
  return extractedDate
}

export const formatDateToYYYYMMDD = (dateString) => {
  if (!dateString) return null;
  const parts = dateString?.split("T")[0]?.toString()?.split("-");
  if (parts.length !== 3) return null;
  const [year, day, month] = parts;
  if (isNaN(day) || isNaN(month) || isNaN(year)) return null;
  const paddedDay = String(day).padStart(2, "0");
  const paddedMonth = String(month).padStart(2, "0");
  return `${year}-${paddedMonth}-${paddedDay}`;
};

export const equalDayjsDates = (first, second) => {
  return dayjs(first)?.date() === dayjs(second)?.date()
}

export  const mdyFormatDate = (dateString,withSlashes) => {
  const date = new Date(dateString);
  const month = (date.getMonth() + 1).toString().padStart(2, '0');
  const day = date.getDate().toString().padStart(2, '0');
  const year = date.getFullYear();
  const dateToReturn = withSlashes ? `${month}/${day}/${year}` : `${month}-${day}-${year}`
  return dateToReturn;
};

export  const dmyFormat = (dateString,withSlashes) => {
  const date = new Date(dateString);
  const month = (date.getMonth() + 1).toString().padStart(2, '0');
  const day = date.getDate().toString().padStart(2, '0');
  const year = date.getFullYear();
  const dateToReturn = withSlashes ? `${day}/${month}/${year}` : `${day}-${month}-${year}`
  return dateToReturn;
};

export const formatDateToReadable = (dateString) => {
  const [day, month, year] = dateString.split('-').map(Number);
  const monthName = months[month - 1];
  const getOrdinalSuffix = (day) => {
      if (day > 3 && day < 21) return 'th'; 
      switch (day % 10) {
          case 1: return 'st';
          case 2: return 'nd';
          case 3: return 'rd';
          default: return 'th';
      }
  };

  const dayWithSuffix = `${day}${getOrdinalSuffix(day)}`;

// input example: '06-09-2024'
// Output: "September 6th, 2024"

  return `${monthName} ${dayWithSuffix}, ${year}`;
};


export const ydmDateFormat=(date) =>{
  return dayjs(date).format('YYYY-DD-MM').toString()
}

export const dayJsMdyFormat = (date) => {
  return date ? dayjs(date).format('MM-DD-YYYY') : null
}