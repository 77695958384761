import React, { useState } from "react";
import { unitedStates } from "../../utils/staticData";
import './styles.css'
import { sharedStyles } from "../../sharedStyles";

const PersonalInfoForm = ({
    personalInfo, 
    setPersonalInfo
}) => {
    return (
        <div>
            <h2>WEIGHT LOSS FOLLOW UP QUESTIONNAIRE</h2>
            <div className="personal-info">
                {/* First Name */}
                <div className="form-group">
                    <label className="label-form">
                        First Name <span className="required">*</span>
                    </label>
                    <input 
                    className="followUpInputs"
                        type="text"
                        value={personalInfo?.firstName ?? ''}
                        onChange={(e) => setPersonalInfo({...personalInfo,firstName:e.target.value})}
                        placeholder="First Name"
                        maxLength="100"
                        required
                        readOnly
                    />
                </div>

                {/* Last Name */}
                <div className="form-group">
                    <label className="label-form">
                        Last Name <span className="required">*</span>
                    </label>
                    <input className="followUpInputs"
                        type="text"
                        value={personalInfo?.lastName ?? ''}
                        onChange={(e) => setPersonalInfo({...personalInfo,lastName:e.target.value})}
                        placeholder="Last Name"
                        maxLength="100"
                        required
                        readOnly
                    />
                </div>

                {/* Date of Birth */}
                <div className="form-group">
                    <label className="label-form">
                        Date of Birth <span className="required">*</span>
                    </label>
                    <input className="followUpInputs"
                        type="date"
                        value={personalInfo?.dob ?? ''}
                        onChange={(e) => setPersonalInfo({...personalInfo,dob:e.target.value})}
                        required
                        min="2006-01-01"
                        max="2100-12-31"
                        readOnly
                    />
                </div>

                {/* Phone */}
                <div className="form-group">
                    <label className="label-form">
                        Phone
                         <span className="required">*</span>
                    </label>
                    <input className="followUpInputs"
                        type="tel"
                        value={personalInfo?.phone ?? ''}
                        onChange={(e) => setPersonalInfo({...personalInfo,phone:e.target.value})}
                        placeholder="Phone"
                        // required
                        pattern="[0-9]{10}"
                        maxLength={10}
                        title="Please enter a valid 10-digit phone number."
                        readOnly
                    />
                </div>

                {/* Email */}
                <div className="form-group">
                    <label className="label-form">
                        Email <span className="required">*</span>
                    </label>
                    <input className="followUpInputs"
                        type="email"
                        value={personalInfo?.email ?? ''}
                        // onChange={(e) => setPersonalInfo({...personalInfo,email:e.target.value})}
                        placeholder="Email"
                        required
                        readOnly
                    />
                </div>

                {/* Address */}
                <div className="form-group">
                    <label className="label-form">
                        Address <span className="required">*</span>
                    </label>
                    <input className="followUpInputs"
                        type="text"
                        value={personalInfo?.address ?? ''}
                        onChange={(e) => setPersonalInfo({...personalInfo,address:e.target.value})}
                        placeholder="Address"
                        required
                        readOnly
                    />
                </div>

                {/* City */}
                <div className="form-group">
                    <label className="label-form">
                        City <span className="required">*</span>
                    </label>
                    <input className="followUpInputs"
                        type="text"
                        value={personalInfo?.city ?? ''}
                        onChange={(e) => setPersonalInfo({...personalInfo,city:e.target.value})}
                        placeholder="City"
                        required
                        readOnly
                    />
                </div>

                {/* State */}
                 <div className="form-group">
            <label className="label-form">
                State <span className="required">*</span>
            </label>
            <select
                className="forms-inputs-select"
                value={personalInfo?.state}
                onChange={(e) => setPersonalInfo({...personalInfo,state:e.target.value})}
                required
                title="Please select a valid state."
                disabled
                style={sharedStyles?.disabledSelect}
            >
                {unitedStates?.map(({ name, code }) => (
                    <option key={name} value={code}>
                        {name}
                    </option>
                ))}
            </select>
        </div>

                {/* Zip */}
                <div className="form-group">
                    <label className="label-form">
                        Zip Code <span className="required">*</span>
                    </label>
                    <input className="followUpInputs"
                        type="text"
                        value={personalInfo?.zip ?? ''}
                        onChange={(e) => setPersonalInfo({...personalInfo,zip:e.target.value})}
                        placeholder="54321"
                        maxLength="5"
                        pattern="[0-9]{5}"
                        required
                        readOnly
                        
                        title="Please enter a valid 5-digit zip code."
                    />
                </div>
            </div>
        </div>
    );
};

export default PersonalInfoForm;
