import { Dashboard, Search, Security } from '@mui/icons-material'
import React, { useState } from 'react'
import Dropdown from './Dropdown'
import { FaRegCircleCheck } from "react-icons/fa6";

const EligibilityDropdown = ({verifiedUserNpi,active}) => {
    const [tabs] = useState([
        {label: "Eligibility",path: "/EligibilityTab", icon: <FaRegCircleCheck size={15} /> },
        {
            label: "Search ",path: "/EligibilityTab?preSelectedTab=1", icon: <Search fontSize="small" />
        },
        {
            label: "Prior Authorization", path: "/EligibilityTab?preSelectedTab=2",icon:<Security fontSize='small'/>
        },
    ])
  return (
    <Dropdown
    tabs={tabs}
    verifiedUserNpi={verifiedUserNpi}
    heading={'Eligibility'}
    path={'/EligibilityTab'}
    active={active}
    />
  )
}

export default EligibilityDropdown