import { colors } from "../../utils/colors";

export const styles = {
    graphContainer: {
        justifyContent: 'space-between', mb: 2
    },
    cardValue: {
        fontWeight: 'bold'
    },
    cardText: { marginY: 2 },
    discoveryTablesContainer: {
        display: 'flex',
        flexDirection: 'column',
        gap: 5
    },
    scheduledActionsContainer: {
        gap: 2,
        justifyContent: 'flex-end',
        marginTop: 0.5
    },
    detailsButton: {
        backgroundColor: colors?.green,
        border: 'none',
        color: colors?.white,
        fontSize: 'small'
    },
    statusStyle: {
        backgroundColor: colors?.white,
        color: colors?.themeGreen,
    },
    formHelperStyles: {
        color: colors?.errorColor
    }
}