// Questionnaire.js
import React, { useState } from "react";
import "./FullQuestionnaire.css";
import './PersonalInfo.css';
import { belugaMedications, formMedications, restrictedMedications } from "../../utils/staticData";
import { questionsString } from "./Questions/QuestionString";
import { sharedStyles } from "../../sharedStyles";
import toast from "react-hot-toast";


export const QuestionFive = ({
    medicalConditions,
    setmedicalConditions,
    isQ6Disabled,
    setIsQ6Disabled,
    inputError
}) => (
    <div className="question1">
        <label className="label-form">
            <strong>Q5. Please identify all your current medical conditions:*</strong>
        </label>
        <textarea className="textarea1"
            placeholder="List your medical conditions (write 'none' if you have no medical conditions)."
            value={medicalConditions ?? ''}
            onChange={(e) => setmedicalConditions(e.target.value)}
            disabled={isQ6Disabled}
            style={inputError ? sharedStyles?.errorField : null}
        />
        {/* <div style={{ display: 'flex', alignItems: 'center', marginTop: '8px' }}>
            <input className="form-checkbox-radio"
                type="checkbox"
                checked={isQ6Disabled}
                onChange={() => setIsQ6Disabled(prev => !prev)}
                style={{ marginRight: '8px' }}
            />
            <label className="label-form">Skip this question</label>
        </div> */}
    </div>
);



export const QuestionSix = ({
    selfReportedMeds,
    setSelfReportedMeds,
    isQ6Disabled,
    setIsQ6Disabled,
    inputError
}) => (
    <div className="question1">
        <label className="label-form">
            <strong>Q6. Please list all your current medications including dosages:*</strong>
        </label>
        <textarea className="textarea1"
            placeholder="List your medications and dosages (write 'none' if you're not taking any)."
            value={selfReportedMeds ?? ''}
            onChange={(e) => setSelfReportedMeds(e.target.value)}
            disabled={isQ6Disabled}
            style={inputError ? sharedStyles?.errorField : null}
        />
        {/* <div style={{ display: 'flex', alignItems: 'center', marginTop: '8px' }}>
            <input className="form-checkbox-radio"
                type="checkbox"
                checked={isQ6Disabled}
                onChange={() => setIsQ6Disabled(prev => !prev)}
                style={{ marginRight: '8px' }}
            />
            <label className="label-form">Skip this question</label>
        </div> */}
    </div>
);


export const QuestionSeven = ({
    allergies,
    setAllergies,
    isQ7Disabled,
    setIsQ7Disabled,
    inputError
}) => (
    <div className="question1">
        <label className="label-form">
            <strong>Q7. Please list all of your known allergies:*</strong>
        </label>
        <textarea className="textarea1"
            placeholder="List your allergies (write 'none' if you have none)."
            value={allergies ?? ''}
            onChange={(e) => setAllergies(e.target.value)}
            disabled={isQ7Disabled}
            style={inputError ? sharedStyles?.errorField : null}
        />
        {/* <div style={{ display: 'flex', alignItems: 'center', marginTop: '8px' }}>
            <input className="form-checkbox-radio"
                type="checkbox"
                checked={isQ7Disabled}
                onChange={() => setIsQ7Disabled(prev => !prev)}
                style={{ marginRight: '8px' }}
            />
            <label className="label-form">Skip this question</label>
        </div> */}
    </div>
);


export const QuestionEight = ({ gastricBypass, setGastricBypass }) => {
    const options = [
        { value: "yes", label: "Yes" },
        { value: "no", label: "No" },
    ];

    return (
        <div className="question1">
            <label className="label-form">
                <strong>Q8. Have you had a gastric bypass in the past 6 months?*</strong>
            </label>
            <div className="radio-group">
                {options.map(({ value, label }) => (
                    <label className="label-form" key={value}>
                        <input className="form-checkbox-radio"
                            type="radio"
                            name="gastricBypass"
                            value={value}
                            checked={gastricBypass === value}
                            onChange={(e) => setGastricBypass(e.target.value)}
                        />
                        {label}
                    </label>
                ))}
            </div>
        </div>
    );
};


export const QuestionNine = ({ medicationAllergies, handleMedicationAllergiesChange }) => {
    return (
        <div className="question1">
            <label className="label-form">
                <strong>Q9. Are you allergic to any of the following?*</strong>
            </label>
            <div className="checkbox-group">
                {formMedications?.map(({ value, label }) => (
                    <label className="label-form" key={value}>
                        <input 
                            className="form-checkbox-radio"
                            type="checkbox"
                            value={value}
                            onChange={handleMedicationAllergiesChange}
                            checked={medicationAllergies?.includes(value)} 
                        />
                        {label}
                    </label>
                ))}
            </div>
        </div>
    );
};



export const QuestionTen = ({ takingMedications, handleTakingMedicationsChange }) => {
    return (
        <div className="question1">
            <label className="label-form">
                <strong>Q10. Do you take any of the following medications?*</strong>
            </label>
            {restrictedMedications?.map((medication, index) => (
                <p key={index}>{index + 1}. {medication}</p>
            ))}

            <div className="radio-group">
                {["yes", "no"].map((option) => (
                    <label className="label-form" key={option}>
                        <input className="form-checkbox-radio"
                            type="radio"
                            value={option}
                            checked={takingMedications === option}
                            onChange={handleTakingMedicationsChange}
                        />
                        {option.charAt(0).toUpperCase() + option.slice(1)}
                    </label>
                ))}
            </div>
        </div>
    );
};


// Q11: Medication Intake
export const QuestionEleven = ({
    medication,
    handleMedicationChange,
    experiencedSideEffects,
    handleSideEffectsChange,
    sideEffectsDescription,
    setSideEffectsDescription,
    doseSelection,
    handleDoseChange,
    treatmentContinuation,
    handleTreatmentContinuationChange,
    hasPrescriptionPicture,
    handlePrescriptionPictureChange,
    handleFileUpload,
}) => {
    const handleFileChange = (event) => {
        const file = event.target.files[0];
        const maxSizeInMB = 3;

        if (file && file.size / 1024 / 1024 > maxSizeInMB) {
            toast.error(`File size should be less than ${maxSizeInMB} MB.`);
            event.target.value = null;
        } else {
            handleFileUpload(event);
        }
    };
    return (
        <div className="question1">
            <label className="label-form">
                <strong>
                    Q11. Are you currently, or have you in the past two months, taken any of the following medications?*
                </strong>
            </label>
            <div>
                <label className="label-form">
                    <input className="form-checkbox-radio"
                        type="radio"
                        name="medication"
                        value="Semaglutide (Ozempic, Wegovy, Rybelsus)"
                        onChange={handleMedicationChange}
                    />
                    Semaglutide (Ozempic, Wegovy, Rybelsus)
                </label>
                <label className="label-form">
                    <input className="form-checkbox-radio"
                        type="radio"
                        name="medication"
                        value="Tirzepatide (Zepbound, Mounjaro)"
                        onChange={handleMedicationChange}
                    />
                    Tirzepatide (Zepbound, Mounjaro)
                </label>
                <label className="label-form">
                    <input className="form-checkbox-radio"
                        type="radio"
                        name="medication"
                        value="None of these"
                        onChange={handleMedicationChange}
                    />
                    None of these
                </label>
            </div>

            {(medication === 'Semaglutide (Ozempic, Wegovy, Rybelsus)' || medication === 'Tirzepatide (Zepbound, Mounjaro)') && (
                <>
                    <label className="label-form">
                        <strong>Q:- Have you experienced side effects from your current medication?</strong>
                    </label>
                    <div>
                        <label className="label-form">
                            <input className="form-checkbox-radio"
                                type="radio"
                                name="sideEffects"
                                value="Yes"
                                onChange={handleSideEffectsChange}
                            />
                            Yes
                        </label>
                        <label className="label-form">
                            <input className="form-checkbox-radio"
                                type="radio"
                                name="sideEffects"
                                value="No"
                                onChange={handleSideEffectsChange}
                            />
                            No
                        </label>
                    </div>

                    {experiencedSideEffects === 'Yes' && (
                        <>
                            <label className="label-form">
                                <strong>Q:- Please describe the side effects that you've experienced</strong>
                            </label>
                            <textarea className="textarea1"
                                value={sideEffectsDescription ?? ''}
                                onChange={(e) => setSideEffectsDescription(e.target.value)}
                                placeholder="Describe your side effects"
                            />

                        </>
                    )}
                    <>
                    
                    <label className="label-form">
                                <strong>Q:- Which medication and dose most closely matches your most recent dose?</strong>
                            </label>
                            <div>
                            <select value={doseSelection} onChange={handleDoseChange}>
                                <option value="">Select a dose</option>

                              {medication?.includes('Semaglutide') &&  <optgroup label="Semaglutide">
                                    {belugaMedications
                                        .filter((med) => med.name.includes("Semaglutide"))
                                        .map((med) => (
                                            <option key={med.medId} value={`${med.name}`}>
                                                {med.name}
                                            </option>
                                        ))}
                                </optgroup>}

                                {
                                  medication?.includes('Tirzepatide') &&   <optgroup label="Tirzepatide">
                                    {belugaMedications
                                        .filter((med) => med.name.includes("Tirzepatide"))
                                        .map((med) => (
                                            <option key={med.medId} value={`${med.name}`}>
                                                {med.name}
                                            </option>
                                        ))}
                                </optgroup>}
                            </select>
                            </div>

                            <label className="label-form">
                                <strong>Q:- How would you like to continue your treatment?</strong>
                            </label>
                            <div>
                                {['Stay at the same dose or equivalent dose', `Increase the dose if a higher one is available, or continue with my current dose if it's already at the maximum`, 'Decrease dose'].map((value) => (
                                    <label className="label-form" key={value}>
                                        <input className="form-checkbox-radio"
                                            type="radio"
                                            value={value}
                                            checked={treatmentContinuation === value}
                                            onChange={handleTreatmentContinuationChange}
                                        />
                                        {value === 'Stay at the same dose or equivalent dose' && 'Stay at the same dose or equivalent dose'}
                                        {value === `Increase the dose if a higher one is available, or continue with my current dose if it's already at the maximum` && 'Increase dose if higher available or continue current'}
                                        {value === 'Decrease dose' && 'Decrease dose'}
                                    </label>
                                ))}
                            </div>
                    </>

                    <label className="label-form">
                        <strong>{questionsString?.prescriptionImage}</strong>
                    </label>
                    <div>
                        <label className="label-form">
                            <input className="form-checkbox-radio"
                                type="radio"
                                name="prescriptionPicture"
                                value="Yes"
                                onChange={handlePrescriptionPictureChange}
                            />
                            Yes
                        </label>
                        <label className="label-form">
                            <input className="form-checkbox-radio"
                                type="radio"
                                name="prescriptionPicture"
                                value="No"
                                onChange={handlePrescriptionPictureChange}
                            />
                            No
                        </label>
                    </div>

                    {hasPrescriptionPicture === 'Yes' && (
                        <>
                            <label className="label-form">
                                <strong>{questionsString?.uploadPrescription}</strong>
                            </label>
                            <input className="forms-inputs-one" type="file" accept=".jpeg, .pdf" onChange={handleFileChange} />
                        </>
                    )}
                </>
            )}
        </div>
    );
};

// Q12: Identity Verification
export const QuestionTwelve = ({ idImage, handleIdImageChange }) => {
    const handleFileChange = (event) => {
        const file = event.target.files[0];
        const maxSizeInMB = 3;

        if (file && file.size / 1024 / 1024 > maxSizeInMB) {
            toast.error(`File size should be less than ${maxSizeInMB} MB.`);
            event.target.value = null;
        } else {
            handleIdImageChange(event);
        }
    };
    return (
        <div className="question1">
            <label className="label-form">
                <strong>
                    Q12. Identity Verification
                    <br />
                   {questionsString?.twelve}
                </strong>
            </label>
            <input className="forms-inputs-one"
                type="file"
                 accept=".jpeg, .pdf"
                onChange={handleFileChange}
            />
            {idImage?.name && (
                <div>
                    <p>Uploaded ID Image: {idImage.name}</p>
                </div>
            )}
        </div>
    );
};


// Q13: Full Body Image
export const QuestionThirteen = ({ fullBodyImage, handleFullBodyImageChange }) => {
    const handleFileChange = (event) => {
        const file = event.target.files[0];
        const maxSizeInMB = 3;

        if (file && file.size / 1024 / 1024 > maxSizeInMB) {
            toast.error(`File size should be less than ${maxSizeInMB} MB.`);
            event.target.value = null;
        } else {
            handleFullBodyImageChange(event);
        }
    };

    return (
        <div className="question1">
            <label className="label-form">
                <strong>
                    Q13. Full Body Image
                    <br />
                    {questionsString?.thirteen}
                </strong>
            </label>
            <input className="forms-inputs-one"
                type="file"
                accept=".jpeg, .pdf"
                onChange={handleFileChange}
            />
            {fullBodyImage?.name && (
                <div>
                    <p>Uploaded Full Body Image: {fullBodyImage.name}</p>
                </div>
            )}
        </div>
    );
};


// Q14: Additional Information
export const Question14 = ({ additionalQuestions, handleAdditionalQuestionsChange,inputError }) => {
    return (
        <div className="question1">
            <label className="label-form">
                <strong>
                    Q14. What other information or questions do you have for the doctor?*
                </strong>
            </label>
            <textarea className="textarea1 border p-2 w-full"
                value={additionalQuestions ?? ''}
                onChange={handleAdditionalQuestionsChange}
                placeholder="Type your questions or information here (write 'none' if you have no questions)."
                rows={4}
                style={inputError ? sharedStyles?.errorField : null}
            />
        </div>
    );
};