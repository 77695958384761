
import React, { useEffect, useState } from "react";
import "../../Questionnaire/FullQuestionnaire.css";
import '../../Questionnaire/PersonalInfo.css';
import ErrorrText from "../../../components/shared/ErrorrText";
import { sharedStyles } from "../../../sharedStyles";
import { belugaMedications, unAcceptedConsentStr } from "../../../utils/staticData";



export const QuestionFive = ({
    medicalConditions,
    setmedicalConditions,
    isQ6Disabled,
    setIsQ6Disabled,
}) => (
    <div className="question1">
        <label className="label-form">
            <strong>Q. Please identify all your current medical conditions:*</strong>
        </label>
        <textarea className="textarea1"
            placeholder="List your medical conditions (write 'none' if you dont have any medical condition)."
            value={medicalConditions ?? ''}
            onChange={(e) => setmedicalConditions(e.target.value)}
            // disabled={isQ6Disabled}
        />
        {/* <div style={{ display: 'flex', alignItems: 'center', marginTop: '8px' }}>
            <input className="form-checkbox-radio"
                type="checkbox"
                checked={isQ6Disabled}
                onChange={() => setIsQ6Disabled(prev => !prev)}
                style={{ marginRight: '8px' }}
            />
            <label className="label-form">Skip this question</label>
        </div> */}
    </div>
);



export const QuestionSix = ({
    selfReportedMeds,
    setSelfReportedMeds,
    isQ6Disabled,
    setIsQ6Disabled,
}) => (
    <div className="question1">
        <label className="label-form">
            <strong>Q. Please list all your current medications including dosages:*</strong>
        </label>
        <textarea className="textarea1"
            placeholder="List your medications and dosages (write 'none' if you're not taking any)."
            value={selfReportedMeds ?? ''}
            onChange={(e) => setSelfReportedMeds(e.target.value)}
        />
    </div>
);


export const QuestionSeven = ({
    allergies,
    setAllergies,
    isQ7Disabled,
    setIsQ7Disabled,
}) => (
    <div className="question1">
        <label className="label-form">
            <strong>Q. Please list all of your known allergies:*</strong>
        </label>
        <textarea className="textarea1"
            placeholder="List your allergies..."
            value={allergies ?? ''}
            onChange={(e) => setAllergies(e.target.value)}
        />
    </div>
);


export const QuestionEight = ({ gastricBypass, setGastricBypass, failedValidations }) => {
    const options = [
        { value: "Yes", label: "Yes" },
        { value: "No", label: "No" },
    ];

    return (
        <div className="question1">
            <label className="label-form">
                <strong>Q. Have you had a gastric bypass in the past 6 months?*</strong>
            </label>
            <div className="radio-group">
                {options.map(({ value, label }) => (
                    <label className="label-form" key={value}>
                        <input className="form-checkbox-radio"
                            type="radio"
                            name="gastricBypass"
                            value={value}
                            checked={gastricBypass === value}
                            onChange={(e) => setGastricBypass(e.target.value)}
                        />
                        {label}
                    </label>
                ))}
            </div>
            {failedValidations?.includes('gastricBypass') && <ErrorrText text={'Sorry, We dont accept patients who have had gastric bypass in last 6 months'}/>}
        </div>
    );
};

export const QuestionPrescribed = ({ medsLastTaken, setMedsLastTaken }) => {
    const options = [
        { value: "Within the past week", label: "Within the past week" },
        { value: "Within the past two weeks", label: "Within the past two weeks" },
        { value: "Within the past month", label: "Within the past month" },
        { value: "It has been over a month", label: "It has been over a month" },
        { value: "It has been over 2 months", label: "It has been over 2 months" },
    ];

    return (
        <div className="question1">
            <label className="label-form">
                <strong>Q.When did you last take your prescribed weight loss medication?
                    *</strong>
            </label>
            <div className="radio-group">
                {options.map(({ value, label }) => (
                    <label className="label-form" key={value}>
                        <input className="form-checkbox-radio"
                            type="radio"
                            name="medsLastTaken"
                            value={value}
                            checked={medsLastTaken === value}
                            onChange={(e) => setMedsLastTaken(e.target.value)}
                        />
                        {label}
                    </label>
                ))}
            </div>
        </div>
    );
};


export const QuestionEleven = ({

    experiencedSideEffects,
    handleSideEffectsChange,
    sideEffectsDescription,
    setSideEffectsDescription,
    doseSelection,
    handleDoseChange,
    treatmentContinuation,
    handleTreatmentContinuationChange,
    missingFields,
    failedValidations
}) => {
    return (
        <div className="question1">
            {(
                <>
                    <label className="label-form">
                        <strong>Q:- Have you experienced side effects from your current medication?</strong>
                    </label>
                    <div>
                        <label className="label-form">
                            <input className="form-checkbox-radio"
                                type="radio"
                                name="sideEffects"
                                value="Yes"
                                checked={experiencedSideEffects === 'Yes'}
                                onChange={handleSideEffectsChange}
                            />
                            Yes
                        </label>
                        <label className="label-form">
                            <input className="form-checkbox-radio"
                                type="radio"
                                name="sideEffects"
                                value="No"
                                checked={experiencedSideEffects === 'No'}
                                onChange={handleSideEffectsChange}
                            />
                            No
                        </label>
                    </div>

                    {experiencedSideEffects === 'Yes' && (
                        <>
                            <label className="label-form">
                                <strong>Q:- Please describe the side effects that you've experienced</strong>
                            </label>
                            <textarea className="textarea1"
                                value={sideEffectsDescription ?? ''}
                                onChange={(e) => setSideEffectsDescription(e.target.value)}
                                placeholder="Describe your side effects"
                            />
                            {failedValidations?.includes('sideEffectsDescription') && <ErrorrText importantField/>}
                        </>
                        
                    )}
                    <>
                    <label className="label-form">
                                <strong>Q:- Which medication and dose most closely matches your most recent dose?</strong>
                            </label>
                            <div>
                                {/* <select value={doseSelection} onChange={handleDoseChange}>
                                    <option value="">Select a dose</option>
                                    <optgroup label="Semaglutide">
                                        {['0.25mg', '0.5mg', '1mg', '1.7mg', '2.4mg'].map((dose) => (
                                            <option key={dose} value={`Semaglutide ${dose}`}>
                                                Semaglutide {dose}
                                            </option>
                                        ))}
                                    </optgroup>
                                    <optgroup label="Tirzepatide">
                                        {['2.5mg', '5mg', '7.5mg', '10mg', '12.5mg', '15mg'].map((dose) => (
                                            <option key={dose} value={`Tirzepatide ${dose}`}>
                                                Tirzepatide {dose}
                                            </option>
                                        ))}
                                    </optgroup>
                                </select> */}
                                <select value={doseSelection} onChange={handleDoseChange}>
                                <option value="">Select a dose</option>

                                <optgroup label="Semaglutide">
                                    {belugaMedications
                                        .filter((med) => med?.name?.includes("Semaglutide"))
                                        .map((med) => (
                                            <option key={med?.medId} value={`${med?.name}`}>
                                                {med?.name}
                                            </option>
                                        ))}
                                </optgroup>

                                <optgroup label="Tirzepatide">
                                    {belugaMedications
                                        .filter((med) => med?.name?.includes("Tirzepatide"))
                                        .map((med) => (
                                            <option key={med?.medId} value={`${med?.name}`}>
                                                {med?.name}
                                            </option>
                                        ))}
                                </optgroup>
                            </select>
                            </div>
                            {missingFields?.includes('doseSelection') && <ErrorrText importantField/>}
                            <label className="label-form">
                                <strong>Q:- How would you like to continue your treatment?</strong>
                            </label>
                            <div>
                                {['Stay at the same dose or equivalent dose', `Increase the dose if a higher one is available, or continue with my current dose if it's already at the maximum`, 'Decrease dose'].map((value) => (
                                    <label className="label-form" key={value}>
                                        <input className="form-checkbox-radio"
                                            type="radio"
                                            value={value}
                                            checked={treatmentContinuation === value}
                                            onChange={handleTreatmentContinuationChange}
                                        />
                                        {value === 'Stay at the same dose or equivalent dose' && 'Stay at the same dose or equivalent dose'}
                                        {value === `Increase the dose if a higher one is available, or continue with my current dose if it's already at the maximum` && `Increase the dose if a higher one is available, or continue with my current dose if it's already at the maximum`}
                                        {value === 'Decrease dose' && 'Decrease dose'}
                                    </label>
                                ))}
                            </div>
                            {missingFields?.includes('treatmentContinuation') && <ErrorrText importantField/>}
                    </>
                </>
            )}
        </div>
    );
};

export const Question14 = ({ additionalQuestions, handleAdditionalQuestionsChange ,inputError}) => {
    return (
        <div className="question1">
            <label className="label-form">
                <strong>
                    Q. What other information or questions do you have for the doctor?*
                </strong>
            </label>
            <textarea className="textarea1 border p-2 w-full"
                value={additionalQuestions ?? ''}
                onChange={handleAdditionalQuestionsChange}
                placeholder="Type your questions or information here (write 'none' if you have no questions)."
                rows={4}
                style={inputError ? sharedStyles?.errorField : null}
            />
        </div>
    );
};

export const QuestionED = ({

    EDvisit,
    // handleEDvisitChange,
    EDvisitDescription,
    setEDvisitDescription,
    setEDvisit
}) => {
    const [showDescription, setShowDescription] = useState(false)
    
    useEffect(()=>{
        EDvisit === 'Yes' ? setShowDescription(true) : setShowDescription(false)
    },[EDvisit])

    return (
        <div className="question1">
            {(
                <>
                    <label className="label-form">
                        <strong>Q:- Have there been any health changes, medication changes, ED visits, or hospitalizations since your last check-in?*</strong>
                    </label>
                    <div>
                        <label className="label-form">
                            <input className="form-checkbox-radio"
                                type="radio"
                                name="ED"
                                value="Yes"
                                checked={EDvisit === 'Yes'}
                                onChange={()=>setEDvisit('Yes')}
                            />
                            Yes
                        </label>
                        <label className="label-form">
                            <input className="form-checkbox-radio"
                                type="radio"
                                name="ED"
                                value="No"
                                checked={EDvisit === 'No'}
                                onChange={()=>setEDvisit('No')}
                            />
                            No
                        </label>
                    </div>

                    {showDescription && (
                        <>
                            <label className="label-form">
                                <strong>Q:-
                                    Please tell us more about the changes in your health
                                </strong>
                            </label>
                            <textarea className="textarea1"
                                value={EDvisitDescription ?? ''}
                                onChange={(e) => setEDvisitDescription(e.target.value)}
                                placeholder="Describe your side effects"
                            />
                        </>
                    )}
                </>
            )}
        </div>
    );
};