import { Autocomplete, Box, CircularProgress, FormControl, IconButton, InputAdornment, TextField } from '@mui/material'
import React, { useEffect, useState } from 'react'
import styles from '../css/cardheader.module.css'
import SearchIcon from '@mui/icons-material/Search';
import FilterListIcon from '@mui/icons-material/FilterList';
import { fetchSearchData, handleSuggestionToShow } from '../Pages/Discovery/DiscoveryFunctions';
import dayjs from 'dayjs';
import { dayJsMdyFormat } from '../utils/DateFunctions';

const TableHeader = ({ setFilterPatient, idNeeded, refetch, setSelectedPatientId }) => {
    const [input, setInput] = useState("");
    const [suggestions, setSuggestions] = useState([]);
    const [suggestionToShow, setSuggestionsToShow] = useState([])
    const [isSuggestionsLoading, setIsSuggestionsLoading] = useState(false)
    const { searchWrapper } = styles;

    useEffect(() => {
        fetchSearchData(setSuggestions, setIsSuggestionsLoading, setInput);
    }, [refetch]);

    return (
        <Box className={searchWrapper}>
            <Box className={searchWrapper}>
                <FormControl fullWidth>
                    <Autocomplete
                        freeSolo
                        options={suggestionToShow?.map((option) => `${option?.firstName} ${option?.middleName} ${option?.lastName} - ${dayJsMdyFormat(option?.dob)}`)}
                        value={input}
                        onInputChange={(e, val) => handleSuggestionToShow(e, val, setSuggestionsToShow, suggestions, setInput)}
                        onChange={(_, val) => {
                            const completeVal = suggestionToShow?.find((({ firstName, lastName, dob, middleName }) =>{
                                return `${firstName} ${middleName} ${lastName} - ${dayJsMdyFormat(dob)}` === val}))
                            setFilterPatient(idNeeded ? completeVal : val);
                            setSelectedPatientId &&  setSelectedPatientId(completeVal?.id)
                        }}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                variant="outlined"
                                placeholder="Search Patients"
                                InputProps={{
                                    ...params.InputProps,
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <IconButton aria-label="search">
                                                {isSuggestionsLoading ? <CircularProgress color="success" size={20} /> : <SearchIcon />}
                                            </IconButton>
                                        </InputAdornment>
                                    )
                                }}
                            />
                        )}
                    />
                </FormControl>
                <IconButton>
                    <FilterListIcon />
                </IconButton>
            </Box>
        </Box>
    )
}

export default TableHeader