import React, { useRef, useState } from "react";
import "./FullQuestionnaire.css"; // External CSS file
import './PersonalInfo.css';
import ConsentSection from './ConsentSection';
import PersonalInfoForm from './PersonalInfoFom';
import {
  QuestionFive, QuestionSix, QuestionSeven,
  QuestionEight, QuestionNine, QuestionTen,
  QuestionEleven,
  QuestionTwelve,
  QuestionThirteen,
  Question14
} from './Questions';

import { belugaImageUpload, belugaNewWeightLossFormSubmit, belugaWeightLossFormSubmit } from '../../services/BelugaService';
import { capitalizeFirtLetter } from "../../utils/reusableFunctions";
import SuccessModal from "./SuccessModal";
import { belugaAllWeightLossAnswers, formatWeightLossAnswers, isQualified, reqFieldsCheck, validateAnswers } from "./Functions";
import toast from "react-hot-toast";
import { additionalmedicalConditions, belugaAllQuestions, BelugaCommonQuestions, belugaMedications, minorSemaglitudeDose, minorTripaztideDose, missingFiedlsStr, onlyWeightLossQuestions, unAcceptedConsentStr } from "../../utils/staticData";
import QuestionOne from "./Questions/QuestionOne";
import QuestionTwo from "./Questions/QuestionTwo";
import QuestionThree from "./Questions/QuestionThree";
import QuestionFour from "./Questions/QuestionFour";
import GlpConsent from "./Questions/GlpConsent";
import { CircularProgress } from "@mui/material";
import { useAuth0 } from "@auth0/auth0-react";
import ErrorrText from "../../components/shared/ErrorrText";
const FullQuestionnaire = () => {
  const { user: { email: authEmail,given_name = '',family_name = '',sub:userId } } = useAuth0()
  const [sex, setSex] = useState("");
  const [userResponse, setUserResponse] = useState('');
  const [showConsent, setShowConsent] = useState(false); // Handles consent popup visibility
  const [isPregnancyQuestionVisible, setPregnancyQuestionVisible] = useState(false);
  const [pregnant, setIsPregnant] = useState("");
  const [glpMedication, setGlpMedication] = useState(""); // State for Q2
  const [firstName, setFirstName] = useState(given_name);
  const [lastName, setLastName] = useState(family_name);
  const [dob, setDob] = useState("");
  const [address, setAddress] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState(authEmail);
  const [city, setCity] = useState('');
  const [state, setState] = useState('AL');
  const [zip, setZip] = useState('');
  const [heightFeet, setHeightFeet] = useState("");
  const [heightInches, setHeightInches] = useState("");
  const [weight, setWeight] = useState("");
  const [bmiConsentAcknowledged, setBmiConsentAcknowledged] = useState("");
  const [gallBladerConsentAcknowledged, setGallBladerConsentAcknowledged] = useState('')
  const [bmi, setBmi] = useState(null);
  const [bmiMessage, setBmiMessage] = useState("");
  const [medicalConditions, setmedicalConditions] = useState("");
  const [showGallbladderConsent, setShowGallbladderConsent] = useState(false);
  const [selectedConditionDetails, setSelectedConditionDetails] = useState("");
  const [showConditionDetails, setShowConditionDetails] = useState(false);
  const [additionalMedicalConditions, setadditionalMedicalConditions] = useState([]);
  const [selfReportedMeds, setSelfReportedMeds] = useState("");
  const [allergies, setAllergies] = useState("");
  const [gastricBypass, setGastricBypass] = useState(""); // Q8
  const [medicationAllergies, setMedicationAllergies] = useState([]);
  const [takingMedications, setTakingMedications] = useState(null); // For Yes or No
  const [isQ6Disabled, setIsQ6Disabled] = useState(false);
  const [isQ7Disabled, setIsQ7Disabled] = useState(false);
  const [experiencedSideEffects, setExperiencedSideEffects] = useState(''); // Tracks if the user experienced side effects
  const [sideEffectsDescription, setSideEffectsDescription] = useState(''); // Holds the description of side effects
  const [treatmentContinuation, setTreatmentContinuation] = useState(''); // Tracks how user wants to continue treatment
  const [prescriptionImage, setPrescriptionImage] = useState(null); // To store the uploaded image
  const [idImage, setIdImage] = useState(null);
  const [fullBodyImage, setFullBodyImage] = useState(null);
  const [additionalQuestions, setAdditionalQuestions] = useState('');
  const [medication, setMedication] = useState('');
  const [doseSelection, setDoseSelection] = useState('');
  const [hasPrescriptionPicture, setHasPrescriptionPicture] = useState('');
  const [consentsSigned, setConsentsSigned] = useState(false)
  const [showModal, setShowModal] = useState(false)
  const [userConsent, setUserConsent] = useState({ pregnancy: false, bmi: false, gallBlader: false, truthFull: false, glp: false })
  const [loading, setLoading] = useState(false)
  const [failedValidations, setFailedValidations] = useState([])
  const [missingFields, setMissingFields] = useState([])

  //refs
  const sectionRefs = useRef({
    personalInfo: null,
    questionOne: null,
    questionTwo: null,
    questionThree: null,
    questionFour: null,
    questionFive: null,
    questionSix: null,
    questionSeven: null,
    questionEight: null,
    questionNine: null,
    questionTen: null,
    questionEleven: null,
    questionFourteen: null,
    truthFullConsent: null,
    glpConsnet: null
  });


  const handleSubmit = async () => {
    if (reqFieldsCheck({
      firstName,
      lastName,
      dob,
      phone,
      email,
      address,
      city,
      state,
      zip,
      glpMedication,
      heightInches,
      heightFeet,
      weight,
      additionalMedicalConditions,
      gastricBypass,
      medicationAllergies,
      takingMedications,
      medication,
      additionalQuestions,
      sex,
      medicalConditions,
      selfReportedMeds,
      allergies,
      setMissingFields,
      sectionRefs,
      // fullBodyImage,
      // idImage
    })) {
      if (validateAnswers(userConsent, sex, pregnant, bmi, additionalMedicalConditions, selectedConditionDetails, gastricBypass, medicationAllergies, takingMedications, setFailedValidations, failedValidations, sectionRefs,medication,experiencedSideEffects,sideEffectsDescription,doseSelection,treatmentContinuation,hasPrescriptionPicture,prescriptionImage)) {
        setLoading(true)
        const belugaQuestionAndAnswers = belugaAllQuestions?.map((elem, i) => {
          return (
            {
              question: elem,
              answer: belugaAllWeightLossAnswers(sex, pregnant, heightFeet, heightInches, weight, bmi, gastricBypass, experiencedSideEffects, sideEffectsDescription, doseSelection, treatmentContinuation, additionalQuestions, glpMedication, medicalConditions, medicationAllergies, medication, selectedConditionDetails, additionalMedicalConditions, prescriptionImage)[i]

            }
          )
        })?.filter(elem => {
          return elem?.answer !== null
        })
        const selectedDose = hasPrescriptionPicture?.toLowerCase() !== 'no' ? belugaMedications?.find(elem => elem?.name === doseSelection) : doseSelection?.toLowerCase()?.includes('semaglutide') ? minorSemaglitudeDose : minorTripaztideDose
        const preferenceList = [
          {
            "name": selectedDose?.name?.split(' ')?.[0] ,
            "strength": selectedDose?.name?.split(' ')?.slice(1)?.join(' '),
            "quantity": selectedDose?.quantity?.toString() ?? '1',
            "refills": "0",
            "medId" :selectedDose?.medId
          }
        ];
        const payload = {
          consentsSigned: true,
          firstName,
          lastName,
          dateOfBirth: dob && new Date(dob)?.toISOString(),
          address,
          phone,
          email,
          city,
          state,
          zip,
          sex: capitalizeFirtLetter(sex),
          selfReportedMeds,
          knownAllergies: allergies,
          currentWeightLoss: medication?.includes('Semaglutide') || medication?.includes('Tirzepatide') ? medication?.split(' ')?.[0]?.toLowerCase() :medication ,
          weightlossPreference: treatmentContinuation?.includes('same') ? 'same' : treatmentContinuation?.includes('Increase') ? 'increase' :treatmentContinuation?.includes('Decrease') ? 'decrease' : 'N/A',
          currentDose: selectedDose?.category ?? 'N/A',
          visitType: "weightloss",
          questions: belugaAllQuestions,
          answers: belugaAllWeightLossAnswers(sex, pregnant, heightFeet, heightInches, weight, bmi, gastricBypass, experiencedSideEffects, sideEffectsDescription, doseSelection, treatmentContinuation, additionalQuestions, glpMedication, medicalConditions, medicationAllergies, medication, selectedConditionDetails, additionalMedicalConditions, prescriptionImage),
          preferenceList,
          pharmacyId: selectedDose?.pharmacyId?.toString() ?? "pharmacy789",
          company: "bradysWellnessRx",
          npiNumber: 1285170167,
          weightlossfollowup: "N/A",
          currentOrPastMedicalConditions: medicalConditions,
          finalConsent: true,
          belguaAnswers:belugaQuestionAndAnswers?.map(elem => elem?.answer),
          belguaQuestions: belugaQuestionAndAnswers?.map(elem => elem?.question)
        };
        try {
          const formSubmitted = await belugaNewWeightLossFormSubmit(payload);
          if (formSubmitted) {
            const {patientId, visitId} = formSubmitted?.data
           fullBodyImage && await belugaImageUpload(fullBodyImage,patientId,visitId,userId, 'Full body image')
          idImage &&  await belugaImageUpload(idImage,patientId,visitId,userId, 'Identity image')
            prescriptionImage && await belugaImageUpload(prescriptionImage,patientId,visitId,userId, 'Prescription image')
            setShowModal(true)
          }
        } catch (error) {
          console.log(error)
        }
        finally {
          setLoading(false)
        }
      }
    }
    else toast.error(missingFiedlsStr)
  };

  const handlePregnancyChange = (event) => {
    const pregnant = event.target?.value;

    setShowConsent(true);
    if (pregnant) {
      setIsPregnant(pregnant);
    }
  };

  const handleMedicalConditionChange = (e) => {
    const { value, checked } = e.target ?? {};
    setadditionalMedicalConditions((prev) => {
      // Handle the "none" condition
      if (value === "none") {
        return checked ? ['none'] : [];
      }
      if (checked) {
        return prev.includes('none')
          ? [...prev.filter(condition => condition !== 'none'), value]
          : [...prev, value];
      } else {
        return prev.filter(condition => condition !== value);
      }
    });

    // Handle gallbladder consent
    if (value === "gallbladder_removed") {
      setShowGallbladderConsent(checked);
    }

    // Handle conditions that require additional details
    const conditionsRequiringDetails = [
      "hypertension", "liver_disease", "heart_disease",
      "metabolic_syndrome", "chronic_kidney_disease",
      "siadh", "thyroid_issues", "type_2_diabetes"
    ];

    if (conditionsRequiringDetails.includes(value)) {
      if (checked) {
        setShowConditionDetails(true);
      } else {
        const remainingConditions = additionalMedicalConditions.filter(
          (condition) => condition !== value
        );
        if (!remainingConditions.some((condition) => conditionsRequiringDetails.includes(condition))) {
          setShowConditionDetails(false);
        }
      }
    }
  };

  const handleMedicationAllergiesChange = (e) => {
    const { value, checked } = e.target ?? {};

    setMedicationAllergies((prev) => {
      if (value === 'none') {
        return checked ? ['none'] : [];
      }

      if (checked) {
        return prev.includes('none')
          ? [...prev.filter(allergy => allergy !== 'none'), value]
          : [...prev, value];
      } else {
        return prev.filter(allergy => allergy !== value);
      }
    });
  };
  return (
    <div className="form-container1">
      <div>
        <div ref={(el) => sectionRefs.current.personalInfo = el}>
          <PersonalInfoForm
            firstName={firstName}
            setFirstName={setFirstName}
            lastName={lastName}
            setLastName={setLastName}
            dob={dob}
            setDob={setDob}
            phone={phone}
            setPhone={setPhone}
            email={email}
            setEmail={setEmail}
            address={address}
            setAddress={setAddress}
            city={city}
            setCity={setCity}
            state={state}
            setState={setState}
            zip={zip}
            setZip={setZip}
            missingFields={missingFields}
          />
        </div>
        <div ref={(el) => sectionRefs.current.questionOne = el} >
          <QuestionOne
            sex={sex}
            setSex={setSex}
            isPregnancyQuestionVisible={isPregnancyQuestionVisible}
            // handleSexChange={handleSexChange}
            pregnant={pregnant}
            handlePregnancyChange={handlePregnancyChange}
            showConsent={showConsent}
            // handleConsentAcknowledge={handleConsentAcknowledge}
            setUserConsent={setUserConsent}
            userConsent={userConsent}
            setShowConsent={setShowConsent}
            setPregnancyQuestionVisible={setPregnancyQuestionVisible}
            failedValidations={failedValidations}

          />
        </div>
        {missingFields?.includes('sex') && <ErrorrText importantField />}
        {missingFields?.includes("pregnancyConsent") && <ErrorrText text={unAcceptedConsentStr} />}
        {failedValidations?.includes("pregnancy") && <ErrorrText importantField />}
        <div ref={(el) => sectionRefs.current.questionTwo = el}>
          <QuestionTwo
            glpMedication={glpMedication}
            handleGlpMedicationChange={(e) => setGlpMedication(e.target?.value)}
          />
        </div>

        {missingFields?.includes("glpMedication") && <ErrorrText importantField />}

        <div ref={(el) => sectionRefs.current.questionThree = el}>
          <QuestionThree
            heightFeet={heightFeet}
            setHeightFeet={setHeightFeet}
            heightInches={heightInches}
            setHeightInches={setHeightInches}
            weight={weight}
            setWeight={setWeight}
            bmi={bmi}
            bmiConsentAcknowledged={bmiConsentAcknowledged}
            setBmiConsentAcknowledged={setBmiConsentAcknowledged}
            setBmi={setBmi}
            setBmiMessage={setBmiMessage}
            setUserConsent={setUserConsent}
            userConsent={userConsent}
            failedValidations={failedValidations}
            missingFields={missingFields}
          />
        </div>

        {failedValidations?.includes("bmiConsent") && <ErrorrText text={unAcceptedConsentStr} />}
        {failedValidations?.includes("lowBmi") && <ErrorrText text={'Sorry, we dont accept patient with bmi lower than 23'} />}

        <div ref={(el) => sectionRefs.current.questionFour = el}>
          <QuestionFour
            handleMedicalConditionChange={handleMedicalConditionChange}
            showGallbladderConsent={showGallbladderConsent}
            // handleRadioChange={handleRadioChange}
            userResponse={userResponse}
            showConditionDetails={showConditionDetails}
            selectedConditionDetails={selectedConditionDetails}
            handleConditionDetailsChange={(e) => setSelectedConditionDetails(e?.target?.value)}
            setUserConsent={setUserConsent}
            userConsent={userConsent}
            setUserResponse={setUserResponse}
            setGallBladerConsentAcknowledged={setGallBladerConsentAcknowledged}
            gallBladerConsentAcknowledged={gallBladerConsentAcknowledged}
            medicalConditions={additionalMedicalConditions}
            failedValidations={failedValidations}
            inputError={missingFields?.includes("additionalMedicalConditions")}
          />
        </div>
        {missingFields?.includes("additionalMedicalConditions") && <ErrorrText importantField />}
        {failedValidations?.includes("medicalConditions") && <ErrorrText text={'Sorry, we dont accept patient with these medical conditions'} />}
        {failedValidations?.includes('gallBladerConsent') && <ErrorrText text={unAcceptedConsentStr} />}
        {failedValidations?.includes('medicalConditionDetail') && <ErrorrText importantField />}

        <div ref={(el) => sectionRefs.current.questionFive = el}>
          <QuestionFive
            isQ6Disabled={isQ6Disabled}
            setIsQ6Disabled={setIsQ6Disabled}
            medicalConditions={medicalConditions}
            setmedicalConditions={setmedicalConditions}
            inputError={missingFields?.includes("medicalConditions")}
          />
        </div>
        {missingFields?.includes("medicalConditions") && <ErrorrText importantField />}
        <div ref={(el) => sectionRefs.current.questionSix = el}>
          <QuestionSix
            selfReportedMeds={selfReportedMeds}
            setSelfReportedMeds={setSelfReportedMeds}
            isQ6Disabled={isQ6Disabled}
            setIsQ6Disabled={setIsQ6Disabled}
            inputError={missingFields?.includes("selfReportedMeds")}
          />
        </div>
        {missingFields?.includes("selfReportedMeds") && <ErrorrText importantField />}
        <div ref={(el) => sectionRefs.current.questionSeven = el}>
          <QuestionSeven
            allergies={allergies}
            setAllergies={setAllergies}
            isQ7Disabled={isQ7Disabled}
            setIsQ7Disabled={setIsQ7Disabled}
            failedValidations={failedValidations}
            inputError={missingFields?.includes("allergies")}
          />
        </div>
        {missingFields?.includes("allergies") && <ErrorrText importantField />}
        <div ref={(el) => sectionRefs.current.questionEight = el}>
          <QuestionEight
            gastricBypass={gastricBypass}
            setGastricBypass={setGastricBypass}
            failedValidations={failedValidations}
          />
        </div>
        {missingFields?.includes("gastricBypass") && <ErrorrText importantField />}
        {failedValidations?.includes("gastricBypass") && <ErrorrText text={'Sorry, we dont accept patient who have had gastric bypass in last 6 months'} />}
        <div ref={(el) => sectionRefs.current.questionNine = el}>
          <QuestionNine handleMedicationAllergiesChange={handleMedicationAllergiesChange} medicationAllergies={medicationAllergies}
            failedValidations={failedValidations}
          />
        </div>
        {failedValidations?.includes("allergies") && <ErrorrText text={'Sorry, we dont accept patient who have any of these allergies'} />}
        {missingFields?.includes("medicationAllergies") && <ErrorrText importantField />}
        <div ref={(el) => sectionRefs.current.questionTen = el}>
          <QuestionTen
            takingMedications={takingMedications}
            handleTakingMedicationsChange={(e) => setTakingMedications(e?.target?.value)}
            failedValidations={failedValidations}
          />
        </div>
        {missingFields?.includes("takingMedications") && <ErrorrText importantField />}
        {failedValidations?.includes("medications") && <ErrorrText text={'Sorry, we dont accept patient who are taking any of these medications'} />}
        <div ref={(el) => sectionRefs.current.questionEleven = el}>
          <QuestionEleven
            medication={medication}
            handleMedicationChange={(e) => {
              setMedication(e?.target?.value)
              setDoseSelection('')
            }}
            experiencedSideEffects={experiencedSideEffects}
            handleSideEffectsChange={(e) => setExperiencedSideEffects(e?.target?.value)}
            sideEffectsDescription={sideEffectsDescription}
            setSideEffectsDescription={setSideEffectsDescription}
            doseSelection={doseSelection}
            handleDoseChange={(e) => setDoseSelection(e?.target?.value)}
            treatmentContinuation={treatmentContinuation}
            handleTreatmentContinuationChange={(e) => setTreatmentContinuation(e?.target?.value)}
            hasPrescriptionPicture={hasPrescriptionPicture}
            handlePrescriptionPictureChange={(e) => setHasPrescriptionPicture(e?.target?.value)}
            handleFileUpload={(e) => setPrescriptionImage(e.target?.files[0])}
            failedValidations={failedValidations}
          />
        </div>
        {missingFields?.includes("medication") && <ErrorrText importantField />}
        {failedValidations?.includes("currMedications") && <ErrorrText text={'Please fill all the info about your current medication'} />}
        {failedValidations?.includes("prescriptionImg") && <ErrorrText text={'Please add your prescription image'} />}
        <QuestionTwelve
          idImage={idImage}
          handleIdImageChange={(e) => setIdImage(e.target?.files[0])}
        />
        {missingFields?.includes("idImage") && <ErrorrText importantField />}
        <QuestionThirteen
          fullBodyImage={fullBodyImage}
          handleFullBodyImageChange={(e) => setFullBodyImage(e.target?.files[0])}
        />
        {missingFields?.includes("fullBodyImage") && <ErrorrText importantField />}
        <div ref={(el) => sectionRefs.current.questionFourteen = el}>
          <Question14
            additionalQuestions={additionalQuestions}
            handleAdditionalQuestionsChange={(e) => setAdditionalQuestions(e?.target?.value)}
            inputError={missingFields?.includes("additionalQuestions")}
          />
        </div>
        {missingFields?.includes("additionalQuestions") && <ErrorrText importantField />}
        <div>
          <ConsentSection
            consentsSigned={consentsSigned}
            setConsentsSigned={setConsentsSigned}
            setUserConsent={setUserConsent}
            userConsent={userConsent}
          />
          {failedValidations?.includes('truthfulnessConsent') && <ErrorrText text={unAcceptedConsentStr} />}
        </div>
        <GlpConsent
          setUserConsent={setUserConsent}
          userConsent={userConsent}
        />
        {failedValidations?.includes('glpConsent') && <ErrorrText text={unAcceptedConsentStr} />}

        <button onClick={handleSubmit} disabled={loading}> {loading ? <CircularProgress color="inherit" size={15} /> : 'Submit'} </button>

      </div>
      <SuccessModal
        open={showModal}
        handleClose={() => setShowModal(false)}
      />
    </div>

  );
}
export default FullQuestionnaire;
