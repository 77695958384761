import React, { useState, useEffect } from "react";
import {
  Box,
  FormControl,
  Button,
  Grid,
  Autocomplete,
  TextField,
  CircularProgress,
  FormHelperText,
} from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { useNavigate } from "react-router-dom";
import styles from "../../css/addpatient.module.css";
import dayjs from "dayjs";
import TableHeader from "../../components/TableHeader";
import { defaultSubscriberPayload, unitedStates } from "../../utils/staticData";
import { sharedStyles } from "../../sharedStyles";
import { get_GetPatientDataById } from "../../services/addPatient";
import { colors } from "../../utils/colors";
import { authInquiry, getPriorAuthResponse } from "../../services/pVerify";
import { get_payersdata } from "../../services/PayerService";
import toast from "react-hot-toast";


const PriorAuth = () => {
  const navigate = useNavigate();
  const { formWrapper } = styles;
  const [patientSelected, setPatientSelected] = useState('');
  const [paRequestId, setPaRequestId] = useState("");
  const [patientId, setPatientId] = useState("");
  const [transactionDate, setTransactionDate] = useState("");
  const [loading, setLoading] = useState(false)
  const [fieldsErr, setFieldsErr] = useState(false);
  const [patientInfo, setPatientInfo] = useState(defaultSubscriberPayload)
  const [payersData, setpayersData] = useState([]);

  const handleChange = (event, value) => {
    if (event && event.target) {
      const { name, value: targetValue } = event.target;
      setPatientInfo((prevState) => ({
        ...prevState,
        [name]: targetValue,
      }));
    }
  };

  const handleSearch = async () => {
    const { patientFirstName, patientLastName, DOSStart, DOSEnd, insuranceName, isSubscriberPatient, patientDOB, patientMemberId, transactionDate } = patientInfo;
    if (!patientFirstName || !patientLastName || !DOSStart || !DOSEnd || !insuranceName || !isSubscriberPatient || !patientDOB || !patientMemberId || !transactionDate) {
      setFieldsErr(true);
      toast.error('Please enter all required fields.')
      return;
    }
    setLoading(true)
    try {
      const response = await authInquiry(patientInfo);
      const { data: { data: { paRequestID, patientId, transactionDate } } } = response;
      console.log('paRequestID', paRequestID)
      if (response?.data) {
        setPatientInfo({
          patientDOB: null,
          patientFirstName: "",
          patientLastName: "",
          patientMemberId: "",
          isSubscriberPatient: "true",
          patientMiddleName: "",
          transactionDate: dayjs(),
          DOSStart: dayjs().subtract(5, 'days').format('YYYY-MM-DD'),
          DOSEnd: dayjs().add(5, 'days').format('YYYY-MM-DD'),
          insuranceName: ""
        });
        setPaRequestId(paRequestID);
        setPatientId(patientId);
        setTransactionDate(transactionDate);
      }
      setFieldsErr(false)
    } catch (error) {
      console.log(error)
    } finally {
      setLoading(false)
    }
  }

  const handleSelectionChange = async () => {
    const patinetData = await get_GetPatientDataById(patientSelected?.id);
    const { data: { firstName, lastName, middleName, dob, memberId } } = patinetData;
    const objToSet = {
      ...patientInfo,
      patientFirstName: firstName || '', patientLastName: lastName || '', patientMiddleName: middleName || '', patientDOB: dayjs(dob).format('YYYY-MM-DD') || '', patientMemberId: memberId || ''
    }
    setPatientInfo(objToSet);
  };

  const handleInsuranceChange = (event, newValue) => {
    setPatientInfo((prevState) => ({
      ...prevState,
      insuranceName: newValue,
    }));
  };

  const fetchData = async () => {
    try {
      const payersdata = await get_payersdata();
      var payers_array = [];
      for (let i = 0; i < payersdata?.data?.length; i++) {
        payers_array.push(payersdata?.data?.[i].payerName)
      }
      setpayersData(payers_array)
    } catch (error) {
      console.error('Error fetching patient data:', error);
    }
  };

  const fetchPriorAuthResponse = async () => {
    try {
      const respone = await getPriorAuthResponse(paRequestId, patientId, transactionDate);
      if (respone?.data) {
        const { data } = respone?.data;
        toast.success(data?.history[0]?.notes);
      }
    } catch (error) {
      console.log(error);
    }
  }


  useEffect(() => {
    if (patientSelected?.id) {
      handleSelectionChange()
    } else {
      setPatientInfo(defaultSubscriberPayload)
    }
  }, [patientSelected])


  useEffect(() => {
    fetchData()
  }, [])


  useEffect(() => {
    if (patientId && transactionDate && paRequestId) {
           fetchPriorAuthResponse();
    }
  }, [patientId, transactionDate, paRequestId]);



  return (
    <Box>
      <TableHeader setFilterPatient={setPatientSelected} idNeeded />
      <Box>
        <Box className={formWrapper}>
          <form>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6} lg={6}>
                <FormControl sx={sharedStyles?.mb2} fullWidth>
                  <TextField
                    label="First Name"
                    variant="outlined"
                    name="patientFirstName"
                    onChange={handleChange}
                    color="success"
                    required
                    error={fieldsErr && !patientInfo?.patientFirstName ? true : false}
                    helperText={fieldsErr && !patientInfo?.patientFirstName ? 'This field is required' : 'Required*'}
                    inputProps={{ maxLength: 20 }}
                    value={patientInfo?.patientFirstName}
                  />
                </FormControl>
              </Grid>

              <Grid item xs={12} sm={6} lg={6}>
                <FormControl sx={sharedStyles?.mb2} fullWidth>
                  <TextField
                    label="Middle Name"
                    variant="outlined"
                    name="patientMiddleName"
                    onChange={handleChange}
                    color="success"
                    inputProps={{ maxLength: 20 }}
                    value={patientInfo?.patientMiddleName}
                  />
                </FormControl>
              </Grid>

              <Grid item xs={12} sm={6} lg={6}>
                <FormControl sx={sharedStyles?.mb2} fullWidth>
                  <TextField
                    label="Last Name"
                    variant="outlined"
                    name="patientLastName"
                    onChange={handleChange}
                    color="success"
                    required
                    inputProps={{ maxLength: 20 }}
                    value={patientInfo?.patientLastName}
                    error={fieldsErr && !patientInfo?.patientLastName ? true : false}
                    helperText={fieldsErr && !patientInfo?.patientLastName ? 'This field is required' : 'Required*'}
                  />
                </FormControl>
              </Grid>

              <Grid item xs={12} sm={6} lg={6}>
                <FormControl sx={{ marginBottom: "2em" }} fullWidth>
                  <Autocomplete
                    options={payersData}
                    getOptionLabel={(option) => option}
                    filterSelectedOptions
                    value={patientInfo?.insuranceName}
                    onChange={handleInsuranceChange}
                    sx={{
                      '& .MuiOutlinedInput-notchedOutline ': {
                        borderRadius: '12px',
                      }
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Insurance Name"
                        variant="outlined"
                        color="success"
                        error={fieldsErr && !patientInfo?.insuranceName ? true : false}
                        helperText={fieldsErr && !patientInfo?.insuranceName ? "This field is required." : "Required*"}
                      />
                    )}
                  />
                </FormControl>
              </Grid>

              <Grid item xs={12} sm={6} lg={6}>
                <FormControl sx={sharedStyles?.mb2} fullWidth>
                  <TextField
                    label="Member ID"
                    error={fieldsErr && !patientInfo?.patientMemberId ? true : false}
                    helperText={fieldsErr && !patientInfo?.patientMemberId ? 'This field is required' : 'Required*'}
                    variant="outlined"
                    color="success"
                    name="patientMemberId"
                    onChange={handleChange}
                    value={patientInfo?.patientMemberId}
                  />
                </FormControl>
              </Grid>

              <Grid item xs={12} sm={6} lg={6}>
                <FormControl sx={sharedStyles?.mb2} fullWidth>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      label="Date of Birth"
                      value={patientInfo?.patientDOB ? dayjs(patientInfo?.patientDOB) : dayjs()}
                      onChange={(value) => setPatientInfo({ ...patientInfo, patientDOB: dayjs(value).format('YYYY-MM-DD') })}

                      sx={{
                        '& .MuiOutlinedInput-root': {
                          '& .MuiOutlinedInput-notchedOutline': {
                            borderColor: colors?.inputBorder,
                          },
                          '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                            borderColor: colors?.green,
                          },
                        },
                        '& .MuiFormLabel-root': {
                          color: colors?.black,
                        },
                        '& .MuiInputLabel-root.Mui-focused': {
                          color: colors?.green,
                        },
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                        />
                      )}
                    />
                    {fieldsErr && !patientInfo?.patientDOB ? (
                      <FormHelperText sx={{ color: colors?.errorFieldHelper }}>
                        This field is required.
                      </FormHelperText>
                    ) : <FormHelperText>
                      Required*
                    </FormHelperText>}
                  </LocalizationProvider>
                </FormControl>
              </Grid>

              <Grid item xs={12} sm={6} lg={6}>
                <FormControl sx={sharedStyles?.mb2} fullWidth>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      label="Transaction Date"
                      value={patientInfo?.transactionDate ? dayjs(patientInfo?.transactionDate) : dayjs()}
                      onChange={(value) => setPatientInfo({ ...patientInfo, transactionDate: dayjs(value).format('YYYY-MM-DD') })}
                      sx={{
                        '& .MuiOutlinedInput-root': {
                          '& .MuiOutlinedInput-notchedOutline': {
                            borderColor: colors?.inputBorder,
                          },
                          '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                            borderColor: colors?.green,
                          },
                        },
                        '& .MuiFormLabel-root': {
                          color: colors?.black,
                        },
                        '& .MuiInputLabel-root.Mui-focused': {
                          color: colors?.green,
                        },
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                        />
                      )}
                    />
                    {fieldsErr && !patientInfo?.transactionDate ? (
                      <FormHelperText sx={{ color: colors?.errorFieldHelper }}>
                        This field is required.
                      </FormHelperText>
                    ) : <FormHelperText>
                      Required*
                    </FormHelperText>}
                  </LocalizationProvider>
                </FormControl>
              </Grid>

              <Grid item xs={12} sm={6} lg={6}>
                <FormControl sx={sharedStyles?.mb2} fullWidth>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      label="DOS From"
                      value={patientInfo?.transactionDate ? dayjs(patientInfo?.DOSStart) : dayjs()}
                      onChange={(value) => setPatientInfo({ ...patientInfo, DOSStart: dayjs(value).format('YYYY-MM-DD') })}
                      sx={{
                        '& .MuiOutlinedInput-root': {
                          '& .MuiOutlinedInput-notchedOutline': {
                            borderColor: colors?.inputBorder,
                          },
                          '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                            borderColor: colors?.green,
                          },
                        },
                        '& .MuiFormLabel-root': {
                          color: colors?.black,
                        },
                        '& .MuiInputLabel-root.Mui-focused': {
                          color: colors?.green,
                        },
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                        />
                      )}
                    />
                    {fieldsErr && !patientInfo?.DOSStart ? (
                      <FormHelperText sx={{ color: colors?.errorFieldHelper }}>
                        This field is required.
                      </FormHelperText>
                    ) : <FormHelperText>
                      Required*
                    </FormHelperText>}
                  </LocalizationProvider>
                </FormControl>
              </Grid>

              <Grid item xs={12} sm={6} lg={6}>
                <FormControl sx={sharedStyles?.mb2} fullWidth>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      label="DOS To"
                      value={patientInfo?.DOSStart ? dayjs(patientInfo?.DOSEnd) : dayjs()}
                      onChange={(value) => setPatientInfo({ ...patientInfo, DOSEnd: dayjs(value).format('YYYY-MM-DD') })}
                      sx={{
                        '& .MuiOutlinedInput-root': {
                          '& .MuiOutlinedInput-notchedOutline': {
                            borderColor: colors?.inputBorder,
                          },
                          '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                            borderColor: colors?.green,
                          },
                        },
                        '& .MuiFormLabel-root': {
                          color: colors?.black,
                        },
                        '& .MuiInputLabel-root.Mui-focused': {
                          color: colors?.green,
                        },
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                        />
                      )}
                    />
                    {fieldsErr && !patientInfo?.DOSEnd ? (
                      <FormHelperText sx={{ color: colors?.errorFieldHelper }}>
                        This field is required.
                      </FormHelperText>
                    ) : <FormHelperText>
                      Required*
                    </FormHelperText>}
                  </LocalizationProvider>
                </FormControl>
              </Grid>

              <Grid item xs={12}>
                <Box sx={[sharedStyles?.displayFlex, sharedStyles?.justifyCenter, sharedStyles?.mt2em]}>
                  <Button variant="outlined" color="inherit">
                    Cancel
                  </Button>
                  <Button disabled={loading} variant="contained" color="success" onClick={handleSearch} sx={sharedStyles?.ml5}>
                    {'Search'}
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </form>
        </Box>
      </Box>
    </Box>
  )
};


export default PriorAuth
