import './App.css';
import './css/Header.css';
import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Route, Routes,Navigate} from "react-router-dom";
import '@aws-amplify/ui-react/styles.css';
import Layout from './Layout/Layout';
import { useAuth0 } from '@auth0/auth0-react';
import { Toaster } from 'react-hot-toast';
import PatientDetail from './Pages/Patients/PatientDetail';
import EditViewProfile from './Pages/Profile/EditViewProfile';
import Profile from './Pages/Profile/Profile';
import NpiDetail from './Pages/Profile/NpiDetail';
import DentalSearch from './Pages/Eligibility/DentalSearch';
import Dentaldetails1 from './Pages/Eligibility/Dentaldetails1';
import ClaimBatchSearchresultdata from './Pages/Claims/ClaimBatchSearchresultdata';
import ImportClaims from './Pages/Claims/ImportClaims';
import BatchClaimResult from './Pages/Claims/BatchClaimResponse';
import PayerDetail from './Pages/Profile/PayerDetail';
import Notification from './Pages/Notifications/Notification';
import Appointments from './Pages/Appointments/Appointments'
import AllRoutes from './Routes';
import FullQuestionnaire from './Pages/Questionnaire/QuestionnaireForm.jsx'
import WeightLossFollowUp from './Pages/QuestionnaireFollowUp/WeightLossFollowUp.jsx';
import PatientLandingPage from './patientPortal/Pages/PatientLandingPage.jsx';
function App() {
  
  const { isAuthenticated, loginWithRedirect, getIdTokenClaims, isLoading, logout, } = useAuth0();
  const [userType,setUserType] = useState('user')

  const checkAuthentication = async () => {
    if (!isLoading) {
      if (!isAuthenticated) {
        loginWithRedirect();
      }

    }
  };

  const domainCheck = () => {
    const currentUrl = window.location.href;
    const baseUrl = new URL(currentUrl).origin;
    if(baseUrl?.includes('belugapatients')){
      setUserType('patient')
    }
  }
  useEffect(() => {
    checkAuthentication();
    domainCheck()
  }, [isLoading, isAuthenticated, logout, getIdTokenClaims, loginWithRedirect]);


  return (
    isAuthenticated ?
      <>
        <Router>
          {
            userType === 'patient' ? (
              <Layout isPatient={true}>
                <Routes>
                  <Route path="/" element={<PatientLandingPage />} />
                  <Route path="/que" element={<></>} />
                  <Route path="/questionnaire" element={<FullQuestionnaire />} />
                  <Route path="/questionnaire-follow-up" element={<WeightLossFollowUp />} />
                </Routes>
              </Layout>
            ) : (
              <Routes>
                <Route
                  path="/*"
                  element={
                    <Layout>
                      <AllRoutes />
                    </Layout>
                  }
                />
              </Routes>
            )
          }
        </Router>
        <Toaster />
      </>
      : null
  );
}
export default App;