import axios from "axios";
import toast from "react-hot-toast";

const API_BASE_URL = process.env.REACT_APP_API_URL;

export const getPVerifyToken = async () => {
    const tokenUrl = 'https://api.pverify.com/Token';
    const payload = {
        Client_Id: process.env.REACT_APP_PVERIFY_CLIENT_ID,
        Client_Secret: process.env.REACT_APP_PVERIFY_CLIENT_SECRET,
        grant_type: process.env.REACT_APP_PVERIFY_GRANT_TYPE,
      };
    try {
        const response = await axios.post(tokenUrl, payload, {
            headers: {
              'Content-Type': 'application/json',
              'Content-Type': 'application/x-www-form-urlencoded'
            },
          });
          return response; 
    } catch (error) {
        toast.error(error?.message);
    }
};

export const getPriorAuthResponse = async (paRequestId,patientId,transactionDate)=>{
    const url = `${API_BASE_URL}/api/PriorAuthorization/pVerify/GetPriorAuthResponse/${paRequestId}/${patientId}/${transactionDate}`;
    try {
      const response = await axios.get(url);
      return response;
    } catch (error) {
      console.log(error)
    }
}

export const authInquiry = async (patientInfo) => {
  const url = `${API_BASE_URL}/api/PriorAuthorization/pVerify/PostPriorAuthInquiry`;
  try {
      const response = await axios.post(url, patientInfo, {
        headers: {
          'Content-Type': 'application/json'
        }
      })
      return response;
  } catch (error) {
      console.log(error)
   
  }
};

export const mbiLookup = async (payload,addressInfo, pverifyToken) => {
  const apiUrl= 'https://api.pverify.com/API/MBIInquiry';
  let obj = payload;
  obj.Address = addressInfo;

  try {
    if (pverifyToken) {
   const response = await axios.post(apiUrl, obj, {
     headers: {
       'Authorization': `Bearer ${pverifyToken}`,
       'Client-API-Id': process.env.REACT_APP_CLIENT_API_ID
     }
   })
   return response;
 }
} catch (error) {
   console.log(error)
}
}