import React, { useEffect, useState } from 'react'
import styles from "../../css/syncpatient.module.css"
import { Box, Button, FormControl, Grid, InputAdornment, InputLabel, MenuItem, OutlinedInput, Select, TextField, Typography } from '@mui/material';
import { postalCodeFormat } from '../../utils/valueFormatter/Formats';
import toast from 'react-hot-toast';
import { colors } from '../../utils/colors';
import { MenuProps, unitedStates } from '../../utils/staticData';
import { sharedStyles } from '../../sharedStyles';
import { useParams } from 'react-router-dom';
import { get_PatientAddress, update_PatientAddress } from '../../services/addPatient';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from 'dayjs';
import PageLoader from '../../components/loader/PageLoader';

const AddressDetails = () => {
    const [patientAddress, setPatientAddress] = useState({ addressLine1: '', addressLine2: '', city: '', state: '', zipCode: '', country: '', reportedDate: dayjs(new Date()) });
    const [isEditing, setIsEditing] = useState(false);
    const [fieldsErr, setFieldsErr] = useState(false);
    const [isLoading, setIsLoading] = useState(false)
    const { id } = useParams();
    const { formWrapper } = styles;

    const handleChange = (e) => {
        if (e.target && e.target.name) {
            const { name, value } = e.target;
            // Validations
            if (name === 'zipCode') {
                let formattedValue = postalCodeFormat(value);
                setPatientAddress((prevDetails) => ({
                    ...prevDetails,
                    [name]: formattedValue,
                }));
                return;
            }
            setPatientAddress((prevState) => ({
                ...prevState,
                [name]: value,
            }));
        }
    };

    const get_Address = async () => {
        setIsLoading(true);
        try {
            const response = await get_PatientAddress(id);
            const { address: { addressLine1, addressLine2, city, state, zipCode, country, reportedDate } } = response;
       
            const stateToFind = unitedStates?.find(item => {
                return item.name === state;
            });

            const objToSet = {
                addressLine1, addressLine2, city, state: stateToFind, zipCode, country, reportedDate: dayjs(reportedDate).format('YYYY-MM-DD')
            };

            if (response?.address) {
                setPatientAddress(objToSet);
            };
        } catch (error) {
            console.log(error);
        } finally {
            setIsLoading(false);
        }
    };


    const handleSubmit = async e => {
        e.preventDefault();
        setIsEditing(false)
        const objToSet = {
            ...patientAddress,
            reportedDate: dayjs(patientAddress?.reportedDate).format('YYYY-MM-DD')
        }
        try {
            let response = await update_PatientAddress(objToSet, id);
            if (response.isSuccessful) {
                toast.success('Patient Address Updated!');
            }
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        get_Address()
    }, [])

    return (
        <Box className={formWrapper}>
            {
                isLoading ?
                    <PageLoader />
                    :
                    <form onSubmit={handleSubmit}>
                        <Grid spacing={2} container>
                            <Grid item xs={12} sm={6} lg={6}>
                                <FormControl
                                    sx={{ marginBottom: "2em", color: "purple" }}
                                    fullWidth
                                >
                                    <TextField
                                        label="Address Line 1"
                                        disabled={!isEditing}
                                        id="outlined-start-adornment"
                                        sx={{
                                            '& .MuiOutlinedInput-notchedOutline ': {
                                                borderRadius: '12px',
                                            }
                                        }}
                                        inputProps={{
                                            maxLength: 20,
                                            minLength: 2,
                                            style: { textTransform: 'capitalize' }
                                        }}
                                        InputProps={{
                                            startAdornment: <InputAdornment position="start"></InputAdornment>,
                                        }}
                                        variant="outlined"
                                        onChange={handleChange}
                                        value={patientAddress?.addressLine1}
                                        name="addressLine1"
                                        color="success"
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={6} lg={6}>
                                <FormControl
                                    sx={{ marginBottom: "2em", color: "purple" }}
                                    fullWidth
                                >
                                    <TextField
                                        label="Address Line 2"
                                        disabled={!isEditing}
                                        id="outlined-start-adornment"
                                        sx={{
                                            '& .MuiOutlinedInput-notchedOutline ': {
                                                borderRadius: '12px',
                                            }
                                        }}
                                        inputProps={{
                                            maxLength: 20,
                                            minLength: 2,
                                            style: { textTransform: 'capitalize' }
                                        }}
                                        InputProps={{
                                            startAdornment: <InputAdornment position="start"></InputAdornment>,
                                        }}
                                        variant="outlined"
                                        onChange={handleChange}
                                        value={patientAddress?.addressLine2}
                                        name="addressLine2"
                                        color="success"
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={6} lg={6}>
                                <FormControl
                                    sx={{ marginBottom: "2em", color: "purple" }}
                                    fullWidth
                                >
                                    <TextField
                                        label="Postal Code"
                                        id="outlined-start-adornment"
                                        sx={{
                                            '& .MuiOutlinedInput-notchedOutline ': {
                                                borderRadius: '12px',
                                            }
                                        }}
                                        disabled={!isEditing}
                                        inputProps={{
                                            maxLength: 10,
                                            style: { textTransform: 'capitalize' }
                                        }}
                                        InputProps={{
                                            startAdornment: <InputAdornment position="start"></InputAdornment>,
                                        }}
                                        variant="outlined"
                                        onChange={handleChange}
                                        value={patientAddress?.zipCode}
                                        name="zipCode"
                                        color="success"
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={6} lg={6}>
                                <FormControl sx={{ marginBottom: "2em" }} fullWidth>
                                    <InputLabel id="payer-collection">State</InputLabel>
                                    <Select
                                        id="policy-holders"
                                        onChange={(e) => {
                                            setPatientAddress({ ...patientAddress, state: e.target.value })
                                        }}
                                        value={patientAddress?.state}
                                        input={<OutlinedInput label="Policy Holder Relationship" />}
                                        MenuProps={MenuProps}
                                        disabled={!isEditing}
                                        sx={{

                                            '& .MuiOutlinedInput-notchedOutline': {
                                                borderRadius: '12px',
                                                borderColor: !patientAddress.state && fieldsErr ? '#d32f2f' : '#00000099',
                                            },
                                            '& .MuiGrid-root': {
                                                height: '30px'
                                            }
                                        }}
                                    >
                                        {
                                            unitedStates?.length && unitedStates?.map((item, index) => (
                                                <MenuItem value={item} key={index}>
                                                    {item?.name}
                                                </MenuItem>
                                            ))
                                        }
                                    </Select>
                                </FormControl>

                            </Grid>
                            <Grid item xs={12} sm={6} lg={6}>
                                <FormControl
                                    sx={{ marginBottom: "2em", color: "purple" }}
                                    fullWidth
                                >
                                    <TextField
                                        label="City"
                                        id="outlined-start-adornment"
                                        sx={{
                                            '& .MuiOutlinedInput-notchedOutline ': {
                                                borderRadius: '12px',
                                            },
                                        }}
                                        disabled={!isEditing}
                                        inputProps={{
                                            maxLength: 20,
                                            minLength: 2,
                                            style: { textTransform: 'capitalize' }
                                        }}
                                        InputProps={{
                                            startAdornment: <InputAdornment position="start"></InputAdornment>,
                                        }}
                                        variant="outlined"
                                        onChange={handleChange}
                                        value={patientAddress?.city}
                                        name="city"
                                        color="success"
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={6} lg={6}>
                                <FormControl
                                    sx={{ marginBottom: "2em", color: "purple" }}
                                    fullWidth
                                >
                                    <TextField
                                        label="Country"
                                        id="outlined-start-adornment"
                                        sx={{
                                            '& .MuiOutlinedInput-notchedOutline ': {
                                                borderRadius: '12px',
                                            }
                                        }}
                                        disabled={!isEditing}
                                        inputProps={{
                                            maxLength: 20,
                                            minLength: 2,
                                            style: { textTransform: 'capitalize' }
                                        }}
                                        InputProps={{
                                            startAdornment: <InputAdornment position="start"></InputAdornment>,
                                        }}
                                        variant="outlined"
                                        onChange={handleChange}
                                        value={patientAddress?.country}
                                        name="country"
                                        color="success"
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={6} lg={6}>
                                <FormControl sx={{ marginBottom: "2em" }} fullWidth>
                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <DatePicker
                                            sx={{
                                                '& .MuiOutlinedInput-root': {
                                                    '& .MuiOutlinedInput-notchedOutline': {
                                                        borderRadius: '12px',
                                                    },
                                                },
                                                '& .MuiFormLabel-root': {
                                                    color: (!patientAddress.reportedDate && fieldsErr) ? colors?.errorRed : undefined,
                                                },
                                                '& .MuiInputLabel-root.Mui-focused': {
                                                    color: (!patientAddress.reportedDate && fieldsErr) ? colors?.errorRed : undefined,
                                                },
                                                '& .MuiFormLabel-root': {
                                                    color: colors?.lowOpBlack
                                                },

                                            }}
                                            label="Reported Date"
                                            onChange={(val) =>
                                                setPatientAddress({
                                                    ...patientAddress,
                                                    reportedDate: val,
                                                })
                                            }
                                            name={"reportedDate"}
                                            value={dayjs(patientAddress?.reportedDate)}
                                            variant="outlined"
                                            disabled={!isEditing}
                                            color="success"
                                        />
                                    </LocalizationProvider>

                                </FormControl>
                            </Grid>
                        </Grid>
                        <Box sx={sharedStyles?.flex}>
                            <Button type='button' sx={isEditing ? [sharedStyles?.show, sharedStyles?.whiteBtn] : [sharedStyles?.hide, sharedStyles?.whiteBtn]} onClick={() => {
                                setIsEditing(false)
                                setFieldsErr(false);
                                get_Address()
                            }} variant='contained' color='error'>Cancel</Button>
                            <Button
                                type='button'
                                sx={isEditing ? [sharedStyles?.hide, sharedStyles?.themeBtn] : [sharedStyles?.show, sharedStyles?.themeBtn]}
                                onClick={() => setIsEditing(true)}
                                variant='contained'>
                                Edit Address
                            </Button>
                            <Button type='submit' variant='contained' sx={isEditing ? [sharedStyles?.show, sharedStyles?.themeBtn] : [sharedStyles?.hide, sharedStyles?.themeBtn]}>Update Address</Button>
                        </Box>
                    </form>
            }
        </Box>
    )
};

export default AddressDetails
