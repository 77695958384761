import React, { useEffect, useState } from 'react'
import MyTabs from '../../components/tabs/MyTabs'
import { Box, Button } from '@mui/material'
import { GridComp } from '../../css/GridComponent';
import Discovery from './Discovery';
import Search from './Search';
import { useLocation, useSearchParams } from 'react-router-dom'
import DiscoveryDashboard from './DiscoveryDashboard';
import DiscoveryTables from './DiscoveryTables';

const Discoverytab = () => {
    const [searchParams] = useSearchParams();
    const preSelectedTabParam = searchParams.get('preSelectedTab');
    const [preSelectedTab, setPreSelectedTab] = useState(preSelectedTabParam ? Number(preSelectedTabParam) : 0);
    const location = useLocation()
    const tabs =
        [
            {
                label: "Dashboard ", value: <DiscoveryDashboard />,
                button: 'New Search', changeTab: 2
            },
            {
                label: "Discovery ", value: <DiscoveryTables />,
                button: 'New Search', changeTab: 2
            },
            {
                label: "Search", value: <Search />, changeTab: 0, button: 'Back'
            },
        ]

        useEffect(() => {
            setPreSelectedTab(preSelectedTabParam ? Number(preSelectedTabParam) : 0);
        }, [preSelectedTabParam]);

    return (
        <section className='discovery' key={location?.key}>
            <Box sx={{
                boxShadow: '0 0 2px 0 #919EAB',
                borderRadius: '12px'
            }}>
                <MyTabs tabs={tabs} preSelectedTab={preSelectedTab} />
            </Box>
        </section>
    )
}
export default Discoverytab